<template>
    <v-container fill-height>
        <v-tabs
            vertical
        >
            <v-tab v-if="startDate && endDate" class="my-10">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-icon left>
                                mdi-calendar
                            </v-icon>
                            Choose Dates
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            Start Date: {{startDate}}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            End Date: {{ endDate }}
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab>
            <v-tab v-else>
                <v-icon color="red" left>
                mdi-calendar
                </v-icon>
                    Choose Dates
            </v-tab>
            <v-tab>
                <v-icon left>
                mdi-wrench
                </v-icon>
                Generate CSV
            </v-tab>
            <v-tab-item
                class="pa-10"
            >
                <v-card>
                    <v-card-title>
                        <h2>
                            Work Log Dates
                        </h2>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3>
                                    Choose the range of dates for generating the Hours Worked by Feature CSV.
                                </h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col justify="space-around">
                                <h3>From Date:</h3>
                                <v-date-picker
                                    v-model="startDate"
                                ></v-date-picker>
                            </v-col>
                            <v-col justify="space-around">
                                <h3>To Date:</h3>
                                <v-date-picker
                                    v-model="endDate"
                                ></v-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                    @click="get7paceWorklogs(true)"
                                >Update Worklogs</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item
                class="pa-10"
            >
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title>
                                <h2>
                                    Create CSV
                                </h2>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <h3>
                                            Create a breakdown of hours worked by Feature.
                                        </h3>
                                        <br />
                                        <h3>
                                            Make sure the dates under Choose Dates on the left correspond to the Pay Period you are interested in.
                                        </h3>
                                        <br />
                                        <h3>
                                            This CSV includes the:
                                            <div class="pl-10">
                                                <br/><b><u>Feature #</u>:</b> the Work Item number associated with the Feature.
                                                <br/><br/><b><u>workItem:</u></b> the feature itself and any child Work Items corresponding to the Feature.
                                                <br/><br/><b><u>Added By User:</u></b> the user that added this worklog.
                                                <br/><br/><b><u>Feature Name:</u></b> the Name assigned to Feature.
                                                <br/><br/><b><u>Hours:</u></b> Hours attributed to the Work Item specified.
                                                <br/><br/><b><u>Date:</u></b> Date Hours added. 
                                                <br/><br/><b><u>Total:</u></b> The running sum of the previously listed Work Item hours, the last row with the same Feature # is the total hours worked on that feature.
                                            </div>
                                        </h3>
                                        <br />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn @click="createCsv" class="ma-3">Create CSV</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs>
        <v-row justify="center">
            <v-dialog
                v-model="createCsvDialogSuccess"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Success!
                    </v-card-title>
                    <v-card-text>
                        CSV Successfully Created
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="createCsvDialogSuccess = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="createCsvDialogError"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Error
                    </v-card-title>
                    <v-card-text>
                        There was an issure creating the CSV
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="createCsvDialogError = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="updateDatesDialogSuccess"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Success!
                    </v-card-title>
                    <v-card-text>
                        Work Log Dates have been Successfully Updated <br/><br/>
                        Start Date: {{ startDate }} <br/>
                        End Date: {{ endDate }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="updateDatesDialogSuccess = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="updateDatesDialogError"
                width="500"
            >
                <v-card>
                    <v-card-title>
                        Error
                    </v-card-title>
                    <v-card-text>
                        Issue Updating Work Log Dates 
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="updateDatesDialogError = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
    
    <script>
    import { mapState, mapActions } from 'vuex'
    import axios from 'axios'
    import { DB, doc, getDoc, setDoc } from '@/firebase/db'
    
    export default {
        name: 'RegistrationLanding',
        data() {
            return {
                createCsvDialogSuccess: false,
                createCsvDialogError: false,
                updateDatesDialogSuccess: false,
                updateDatesDialogError: false,
                workLogs: [],
                departmentsPaycor: [],
                csv: [],
                features: [], // maybe change this to features
                laborCodesPaycor: [],
                startDate: new Date(Date.now() - 12096e5).toISOString().substring(0,10),
                endDate: new Date(Date.now()).toISOString().substring(0,10),
                featureLevelWorkLogStartDate: new Date(Date.now() - 12096e5).toISOString().substring(0,10),
                featureLevelWorkLogEndDate: new Date(Date.now()).toISOString().substring(0,10),
            }
        },
        computed: {
            ...mapState(['user']),
        },
        methods: {
            ...mapActions(['monitorAuthState', 'updateUserState', 'refreshAccessTokenPaycor', 'refreshAccessTokenAdo']),

            async createCsv() {
                try {
                    this.csv = []
        
                    this.workLogs.forEach((workLog) => {
        
                        // Adding ChildWorkItems to this.features
                        this.features.some((feature) => {
                            let regex = new RegExp(String(feature.System_Id), 'g')
    
                            if (workLog.WorkItem.HierarchyPath.match(regex) && feature.childWorkItems) {
                                feature.childWorkItems.push(workLog.WorkItem.System_Id)
                            } else if (workLog.WorkItem.HierarchyPath.match(regex)) {
                                feature.childWorkItems = []
                                feature.childWorkItems.push(workLog.WorkItem.System_Id)
                            }
                        })

                        let feature = this.features.find((feature) => {
                            if(feature.childWorkItems) {
                                return feature.childWorkItems.includes(workLog.WorkItemId)
                            }
                            return false
                        })

                        if (feature && feature.childWorkItems) {
                            // debugger
                            let timestamp = new Date(workLog.Timestamp)
                            this.csv.push({
                                'Feature #': feature.System_Id,
                                'workItem': workLog.WorkItemId,
                                'Added By User': workLog.AddedByUser.Name,
                                'Feature Name': feature.System_Title,
                                'Hours': (workLog.PeriodLength/3600).toFixed(2),
                                'Date': ((timestamp.getMonth() > 8) ? (timestamp.getMonth() + 1) : ('0' + (timestamp.getMonth() + 1))) + '/' + ((timestamp.getDate() > 9) ? timestamp.getDate() : ('0' + timestamp.getDate())) + '/' + timestamp.getFullYear(),
                                'Total': 0
                            })
                        }
                    })

                    let featureSet = new Set(this.csv.map((feat) => {
                        return feat['Feature #']
                    }))

                    featureSet.forEach((featureNumber) => {
                        let hourTot = 0
                        this.csv.forEach((feat) => {
                            if (featureNumber === feat['Feature #']) {
                                hourTot = Number(feat['Hours']) + Number(hourTot)
                                feat['Total'] = hourTot
                            }
                        })
                    })
                    this.csv.sort((a, b) => { return a['Feature #'] - b['Feature #'] })
                    this.convertJsonToCsv(this.csv)
                    this.createCsvDialogSuccess = true
                } catch {
                    this.createCsvDialogError = true
                }
            },
    
            convertJsonToCsv(object) {
                const headers = Object.keys(object[0]).toString();
    
                const main = object.map((item) => {
                    return Object.values(item).toString()
                })
    
                const csv = [headers, ...main].join('\n')
    
                this.startCsvDownload(csv)
            },
    
            startCsvDownload(input) {
                const blob = new Blob([input], { type: 'application/csv' })
                const url = URL.createObjectURL(blob)
    
                const a = document.createElement('a')
                a.download = 'hours_by_feature.csv'
                a.href = url
    
                document.body.appendChild(a)
    
                a.click()
    
                a.remove()
    
                URL.revokeObjectURL(blob)
            },

            async loadStartEndDates() {
                const userRef = await doc(DB, 'user', this.user.uid)
                const userSnap = await getDoc(userRef)

                console.log('userSnap.data().featureLevelWorkLogStartDate && userSnap.data().featureLevelWorkLogEndDate:', userSnap.data().featureLevelWorkLogStartDate && userSnap.data().featureLevelWorkLogEndDate)

                if (userSnap.data().featureLevelWorkLogStartDate && userSnap.data().featureLevelWorkLogEndDate) {
                    this.startDate = userSnap.data().featureLevelWorkLogStartDate
                    this.endDate = userSnap.data().featureLevelWorkLogEndDate
                    this.featureLevelWorkLogStartDate = userSnap.data().featureLevelWorkLogStartDate
                    this.featureLevelWorkLogEndDate = userSnap.data().featureLevelWorkLogEndDate
                }

                return userSnap
            },

            async saveStartEndDate() {
                try {
                    const userRef = await doc(DB, 'user', this.user.uid)
                    await setDoc(userRef, { featureLevelWorkLogStartDate: this.featureLevelWorkLogStartDate }, { merge: true })
                    await setDoc(userRef, { featureLevelWorkLogEndDate: this.featureLevelWorkLogEndDate }, { merge: true })
                    this.updateDatesDialogSuccess = true
                    return userRef
                } catch (err) {
                    alert('Function saveStartEndDate() error:\n\n  ' + err.message)
                    return err
                }
            },
    
            // Below is called on mounted() Lifecycle hook, in order
            async get7paceWorklogs(update) {
                try {
                    // const res = await axios.get(`https://nlsnow.timehub.7pace.com/api/odata/v3.2/workLogsWorkItems?$filter=WorklogDate/ShortDate ge ${this.startDate} and Timestamp le ${this.endDate} and WorkItem/System_TeamProject eq 'BriteCore Technologies'`, {
                    const res = await axios.get(`https://nlsnow.timehub.7pace.com/api/odata/v3.2/workLogsWorkItems?$filter=Timestamp ge ${this.startDate} and Timestamp le ${this.endDate} and WorkItem/System_TeamProject eq 'BriteCore Technologies'`, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${process.env.VUE_APP_7PACE_API_KEY}`
                        }
                    })
        
                    this.workLogs = res.data.value

                    if (update) {
                        this.featureLevelWorkLogStartDate = this.startDate
                        this.featureLevelWorkLogEndDate = this.endDate
                        await this.saveStartEndDate()
                    }

                    if (update) {
                        this.updateDatesDialogSuccess = true
                    }
    
                    return res
                } catch(err) {
                    alert('Function get7paceWorklogs() error:\n\n  ' + err.message)
                    if (update) {
                        this.updateDatesDialogError = true
                    }
                    return err
                }
            },

            async getFeatures() {
                try {
                    const res = await axios.get("https://nlsnow.timehub.7pace.com/api/odata/v3.2/workItems/?$select=System_Id,System_TeamProject,System_Title,System_WorkItemType,System_State&$filter=System_WorkItemType eq 'Feature' and System_TeamProject eq 'BriteCore Technologies' and System_State eq 'Active'", {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${process.env.VUE_APP_7PACE_API_KEY}`
                        }
                    })

                    const res2 = await axios.get("https://nlsnow.timehub.7pace.com/api/odata/v3.2/workItems/?$select=System_Id,System_TeamProject,System_Title,System_WorkItemType,System_State&$filter=System_WorkItemType eq 'Feature' and System_TeamProject eq 'BriteCore Technologies' and System_State eq 'Closed'", {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${process.env.VUE_APP_7PACE_API_KEY}`
                        }
                    })

                    const res3 = await axios.get("https://nlsnow.timehub.7pace.com/api/odata/v3.2/workItems/?$select=System_Id,System_TeamProject,System_Title,System_WorkItemType,System_State&$filter=System_WorkItemType eq 'Feature' and System_TeamProject eq 'BriteCore Technologies' and System_State eq 'New'", {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${process.env.VUE_APP_7PACE_API_KEY}`
                        }
                    })

                    this.features = res.data.value.concat(res2.data.value).concat(res3.data.value)

                    return res
                } catch(err) {
                    alert('Function getFeatures() error:\n\n  ' + err.message)
                    return err
                }
            },

        },
        async mounted() {
            this.monitorAuthState()
            await this.loadStartEndDates()
            await this.get7paceWorklogs()
            await this.getFeatures()
        }
    }
    </script>
    
    <style scoped>
    
    </style>
    